/**
 * Location
 *
 * @selector [data-js="Location"]
 * @enabled true
 */
import { base } from 'app/util/base';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	mapRef: 'map',
	mapLoaderRef: 'loader'
};

export default function Location() {
	// Private vars
	const instance = {};
	// let settings = {};
	let intersectionObserver;
	let mapIframe;
	let mapLoader;

	// Private methods
	const activateMap = () => {
		mapLoader.remove();
		mapIframe.src = mapIframe.getAttribute('data-src');
	};

	const onObserve = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				activateMap();

				intersectionObserver.unobserve(mapIframe);
			}
		});
	};

	const bindEvents = () => {
		intersectionObserver.observe(mapIframe);
	};
	const unbindEvents = () => {
		intersectionObserver.disconnect();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// // Get options from element. These will override default settings
		// let options = {};
		// if (instance.el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		// }

		// settings = Object.assign({}, defaults, options);
		mapIframe = instance.ref(config.mapRef);
		mapLoader = instance.ref(config.mapLoaderRef);
		intersectionObserver = new IntersectionObserver(onObserve);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
