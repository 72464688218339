/**
 * Header
 *
 * @selector [data-js="Header"]
 * @enabled true
 */
import {base} from 'app/util/base';
import {resizeeventsManager, scrolleventsManager} from 'app/util/events-manager';
import {pubsub} from 'app/modules/pubsub';
import {clearStyles, setStyles} from 'app/util/dom-helpers';
import {animateProps} from 'app/util/animate';
import {easeOutQuad} from 'app/util/easings';
import {width as vpWidth} from 'app/util/viewport';

const defaults = {
	topMargin: 40, // Hide header when past this scroll amount
	visibilityMargin: 80,
	animDuration: 600,
	defaultHeaderHeight: 148,
	homeHeaderAnimDelay: 400,
	breakpoint: 1200 // where desktop nav changes to mobile nav
};

const config = {
	optionsAttr: 'data-options',
	burgerRef: 'burger',
	overlayRef: 'overlay',
	noScroll: 'header-no-scroll',
	navRef: 'nav',
	navItemRef: 'navItem',
	navLinkRef: 'navLink',
	navMobileRef: 'navMobile'
};

export default function Header() {
	// Private vars
	const instance = {};
	let settings = {};
	let burger;
	let overlay; // Nav overlay on mobile
	let scrollY;
	let lastScrollY = -1;
	let down = false;
	let goingUp = true;
	let isInContent = true;
	let pastTopMargin = false;
	let counteringAnimation = false;
	let visibilityElement;
	let visibleHeight = 0;
	let isAnimating = false;
	let isHome = false;
	let isMobile;
	let headerAlreadyAnimated = false;
	let nav;
	let navItems;
	let navLinks;
	let navMobile;

	// Private methods
	const onScroll = () => {
		scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

		if (scrollY < visibleHeight) {
			isAnimating = false;
		}

		down = lastScrollY === -1 ? false : scrollY > lastScrollY;
		goingUp = lastScrollY === -1 ? true : scrollY < lastScrollY;
		lastScrollY = scrollY;
		pastTopMargin = scrollY > settings.topMargin;
		isInContent = scrollY > visibleHeight - settings.visibilityMargin;

		if (isAnimating && !counteringAnimation) {
			counteringAnimation = true;
			setTimeout(function () {
				scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
				pastTopMargin = scrollY > settings.topMargin;

				if (goingUp) {
					if (instance.getState() !== 'minified' && pastTopMargin) {
						isAnimating = true;
						instance.setState('minified');
						console.log('minified');

						setTimeout(() => {
							isAnimating = false;
						}, settings.animDuration);
					} else {
						instance.setState('');

						console.log('');
					}

					showHeader();
				}
			}, settings.animDuration);
			return;
		}

		// Cannot use "toggle" here, since IE11 does not support the `force` param to classList.toggle
		if (isInContent) {
			instance.el.classList.add('in-content');
		} else {
			instance.el.classList.remove('in-content');
		}

		if (down) {
			instance.el.classList.add('down');
			closeAllNavItems();
		} else {
			instance.el.classList.remove('down');
		}

		if (down && pastTopMargin) {
			if (instance.getState() !== 'hidden' && instance.getState() !== 'hidden-top') {
				isAnimating = true;

				if (instance.getState() === '') {
					instance.setState('hidden-top');
					pubsub.trigger('Header.hide-top');
				} else {
					instance.setState('hidden');
				}

				setTimeout(() => {
					isAnimating = false;
				}, settings.animDuration);
			}

			return;
		}

		if (!down && pastTopMargin) {
			if (instance.getState() !== 'minified') {
				isAnimating = true;
				instance.setState('minified');

				setTimeout(() => {
					isAnimating = false;
				}, settings.animDuration);
			}

			showHeader();
			return;
		}

		if (isHome && !headerAlreadyAnimated) {
			isAnimating = true;
			headerHomeAnimation();
		} else {
			instance.setState('');
			showHeader();
		}
	};

	const headerHomeAnimation = () => {
		document.body.classList.add(config.noScroll);

		let nav = instance.ref('nav');
		let cta = instance.ref('cta');
		let els = [];

		if (cta) {
			els = [nav, cta];
		} else {
			els = [nav];
		}

		const navCtaAnim = () => {
			animateProps({
				el: els,
				duration: 600,
				easing: easeOutQuad,
				props: [
					{
						propName: 'opacity',
						start: 0,
						end: 1
					},
					{
						propName: 'transform',
						start: -6,
						end: 0,
						prefix: 'translateY(',
						suffix: 'px)'
					}
				],
				onComplete: () => {
					clearStyles(els, ['opacity', 'transform']);
					document.body.classList.remove(config.noScroll);
					headerAlreadyAnimated = true;
					isAnimating = false;
				}
			});
		};

		setTimeout(() => {
			setStyles(els, {
				opacity: 0,
				transform: 'translateY(-6px)'
			});

			instance.setState('');
			showHeader();

			pubsub.trigger('Logo.animate', {
				onComplete: () => {
					// Animate in nav and cta
					navCtaAnim();
				}
			});
		}, settings.homeHeaderAnimDelay);
	};

	const showHeader = () => {
		if (!instance.el.classList.contains('show')) {
			instance.el.classList.add('show');
		}
	};

	const openOverlay = () => {
		scrolleventsManager.remove(onScroll);

		document.body.classList.add(config.noScroll);
		burger.setAttribute('data-state', 'open');
		overlay.setAttribute('data-state', 'open');
	};

	const closeOverlay = () => {
		scrolleventsManager.add(onScroll);

		document.body.classList.remove(config.noScroll);
		burger.setAttribute('data-state', '');
		overlay.setAttribute('data-state', '');
	};

	const toggleOverlay = () => {
		if (burger.getAttribute('data-state') === 'open') {
			closeOverlay();
		} else {
			openOverlay();
		}
	};

	const onResize = () => {
		// We assume that the visibilityElement (Hero, HomeExperience) will always be on top.
		if (visibilityElement) {
			visibleHeight = visibilityElement.getBoundingClientRect().height;
		}

		if (vpWidth() > settings.breakpoint && burger.getAttribute('data-state') === 'open') {
			closeOverlay();
		}

		isMobile = vpWidth() <= settings.breakpoint ? true : false;
	};

	const onBurgerClick = () => {
		toggleOverlay();
	};

	const closeAllNavItems = () => {
		navItems.forEach(navItem => {
			navItem.setAttribute('data-state', '');
		});
		navMobile.setAttribute('data-state', '');
	};

	const onNavLinkClick = e => {
		let navItem = e.target.parentNode;

		if (navItem.classList.contains('has-subnav')) {
			e.preventDefault();

			if (navItem.getAttribute('data-state') !== 'open') {
				closeAllNavItems();
				navItem.setAttribute('data-state', 'open');

				if (burger.getBoundingClientRect().height) {
					navMobile.setAttribute('data-state', 'open');
				}
			} else {
				closeAllNavItems();
			}
		}
	};

	const onOutsideClick = () => {
		if (!burger.getBoundingClientRect().height) {
			closeAllNavItems();
		}
	};

	const onInsideClick = e => {
		e.stopPropagation();
	};

	const desktopNav = () => {
		(Array.isArray(navLinks) ? navLinks : [navLinks]).forEach(navLink => {
			navLink.removeEventListener('click', onNavLinkClick);

			nav.addEventListener('mouseleave', onOutsideClick);
			navLink.addEventListener('mouseenter', onNavLinkClick);
		});
	};

	const mobileNav = () => {
		(Array.isArray(navLinks) ? navLinks : [navLinks]).forEach(navLink => {
			navLink.removeEventListener('mouseenter', onNavLinkClick);
			nav.removeEventListener('mouseleave', onOutsideClick);

			navLink.addEventListener('click', onNavLinkClick);
		});
	};

	const initNav = () => {
		if (isMobile) {
			mobileNav();
		} else {
			desktopNav();
		}
	};

	const bindEvents = () => {
		burger.addEventListener('click', onBurgerClick);

		document.addEventListener('click', onOutsideClick);
		nav.addEventListener('click', onInsideClick);

		scrolleventsManager.add(onScroll);
		resizeeventsManager.add(onResize);
	};

	const unbindEvents = () => {
		burger.removeEventListener('click', onBurgerClick);

		(Array.isArray(navLinks) ? navLinks : [navLinks]).forEach(navLink => {
			navLink.removeEventListener('click', onNavLinkClick);
			navLink.removeEventListener('mouseenter', onNavLinkClick);
			nav.removeEventListener('mouseleave', onOutsideClick);
		});

		document.removeEventListener('click', onOutsideClick);
		nav.removeEventListener('click', onInsideClick);

		scrolleventsManager.remove(onScroll);
		resizeeventsManager.remove(onResize);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		burger = instance.ref(config.burgerRef);
		overlay = instance.ref(config.overlayRef);
		nav = instance.ref(config.navRef);
		navItems = instance.ref(config.navItemRef);
		navLinks = instance.ref(config.navLinkRef);
		navMobile = instance.ref(config.navMobileRef);
		isMobile = vpWidth() <= settings.breakpoint ? true : false;

		if (document.body.classList.contains('home')) {
			// Homepage
			// Logo and MainNav animated
			visibilityElement = document.querySelector('[data-js="HomeExperience"]');
			isHome = true;
		} else if (document.body.classList.contains('landingpage')) {
			// Landingpage
			visibleHeight = 1;
			settings.topMargin = settings.defaultHeaderHeight;
		} else if (document.body.classList.contains('details')) {
			// Detail page

			if (document.body.classList.contains('no-hero')) {
				// Same behaviour as landingpage
				visibleHeight = 1;
				settings.topMargin = settings.defaultHeaderHeight;
			} else {
				// No animations in header
				visibilityElement = document.querySelector('[data-js="HeroImage"]');
			}
		}

		onResize();
		onScroll();
		bindEvents();
		initNav();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
