/**
 * EventsTestimonialGrid
 *
 * @selector [data-js="EventsTestimonialGrid"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function EventsTestimonialGrid() {
	// Private vars
	const instance = {};
	let showMoreBtn;
	let row;
	let isExpanded = false;
	let showmorehidden;
	let showlesshidden;

	const bindEvents = () => {
		showMoreBtn.addEventListener('click', toggleContent);
	};

	const unbindEvents = () => {
		showMoreBtn.removeEventListener('click', toggleContent);
	};

	const toggleContent = () => {
		const buttonText = showMoreBtn.querySelector('.Button--text');
		const showmoretext = showmorehidden.getAttribute('value');
		const showlesstext = showlesshidden.getAttribute('value');

		if (isExpanded) {
			row.style.maxHeight = '488px';
			buttonText.textContent = showmoretext;
			row.setAttribute('data-expanded', '');
			showMoreBtn.setAttribute('data-expanded', '');
		} else {
			row.style.maxHeight = 'none';
			buttonText.textContent = showlesstext;
			row.setAttribute('data-expanded', '1');
			showMoreBtn.setAttribute('data-expanded', '1');
		}
		isExpanded = !isExpanded;
	};

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		row = element.querySelector('.eventstestimonialgrid--content');
		showMoreBtn = element.querySelector('#eventsTestimonialGridShowMore');

		// Set initial state
		row.style.maxHeight = '488px';
		row.style.overflow = 'hidden';

		showmorehidden = element.querySelector('#showmorehiddenid');
		showlesshidden = element.querySelector('#showlesshiddenid');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
