/**
 * IndividualSection02
 *
 * @selector [data-js="IndividualSection02"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function ImageTextBlend() {
	// Private vars
	const instance = {};

	const getOverlay = () => instance.el.querySelector('#individualSection02VideoOverlay');

	const getVideo = () => instance.el.querySelector('#individualSection02Video');

	const onOverlayClick = () => {
		const overlay = getOverlay();
		const video = getVideo();

		overlay.remove();
		video.play();
		video.setAttribute('controls', true);
	};

	const bindEvents = () => {
		const overlay = getOverlay();

		overlay.addEventListener('click', onOverlayClick);
	};

	const unbindEvents = () => {
		const overlay = getOverlay();

		overlay.removeEventListener('click', onOverlayClick);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
