/**
 * Panorama
 *
 * @selector [data-js="Panorama"]
 * @enabled true
 */
import { base } from 'app/util/base';

// const defaults = {};

const config = {
	optionsAttr: 'data-options',
	iframeRef: 'iframe',
	panoramaLoaderRef: 'panoloader'
};

export default function Panorama() {
	// Private vars
	const instance = {};
	// let settings = {};
	let intersectionObserver;
	let panoIframe;
	let panoLoader;

	// Private methods
	const activatePanorama = () => {
		panoLoader.remove();
		panoIframe.src = panoIframe.getAttribute('data-src');
	};

	const onObserve = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				if (entry.target === instance.ref(config.iframeRef)) {
					activatePanorama();
					intersectionObserver.unobserve(entry.target);
				}
			}
		});
	};

	const bindEvents = () => {
		intersectionObserver.observe(panoIframe);
	};

	const unbindEvents = () => {
		intersectionObserver.disconnect();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// // Get options from element. These will override default settings
		// let options = {};
		// if (instance.el.hasAttribute(config.optionsAttr)) {
		// 	options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		// }
		//
		// settings = Object.assign({}, defaults, options);
		panoIframe = instance.ref(config.iframeRef);
		panoLoader = instance.ref(config.panoramaLoaderRef);
		intersectionObserver = new IntersectionObserver(onObserve);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
