/**
 * ContactForm
 *
 * @selector [data-js="ContactForm"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { serialize } from 'app/util/form-serialize';
import Pristine from 'pristinejs/dist/pristine';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
	formRef: 'form',
	inputFieldRef: 'input',
	submitBtn: 'submitBtn',
	submitTimeout: 30 * 1000,
	forcedSubmitDelay: 500,
	successMessageRead: 4000
};

export default function ContactForm() {
	// Private vars
	const instance = {};
	let settings = {};
	let form;
	let inputFields;
	let submitBtn;
	let formValidator;

	// Private methods
	const showSuccessMessage = () => {
		const showMessage = () => {
			instance.setState('success');
			submitBtn.disabled = false;

			instance.ref('successMsg').scrollIntoView({ block: 'center' });

			// Do not reset the form, just hide it
			// form.reset();
			// formValidator.reset();
			form.style.display = 'none';
		};

		setTimeout(() => {
			showMessage();

			if (settings.successRedirect && isValidHttpUrl(settings.successRedirect)) {
				setTimeout(() => {
					document.location.href = settings.successRedirect;
				}, config.successMessageRead);
			}
		}, config.forcedSubmitDelay);
	};

	const showErrorMessage = () => {
		const showMessage = () => {
			instance.setState('error');
			submitBtn.disabled = false;
			reloadFormParameters();

			instance.ref('errorMsg').scrollIntoView({ block: 'center' });
		};

		setTimeout(() => {
			showMessage();
		}, config.forcedSubmitDelay);
	};

	const isValidHttpUrl = string => {
		let url;

		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return url.protocol === 'http:' || url.protocol === 'https:';
	};

	// Ideas from here https://www.adminbooster.com/blog/hacking_web2lead_web2case
	const postToSalesforce = formData => {
		let submitTimeoutId, tmpForm;

		// Add an iframe as target after form submit. Also prevents reloading of page.
		let targetIframe = document.createElement('iframe');
		targetIframe.id = 'salesforce-target';
		targetIframe.name = 'salesforce-target';
		targetIframe.src = 'about:blank';
		targetIframe.style.display = 'none';

		targetIframe = instance.el.appendChild(targetIframe);
		targetIframe.onload = () => {
			clearTimeout(submitTimeoutId);

			showSuccessMessage();

			// IE11 does not reset form validation properly. This is a hack to fix this.
			// See: https://stackoverflow.com/questions/52932096/ie11-not-re-validating-form-after-form-reset
			let input = form.querySelector('input[required]');
			if (input) {
				input.value = '';
			}

			// Clean up
			instance.el.removeChild(tmpForm);
			instance.el.removeChild(targetIframe);
		};

		tmpForm = document.createElement('form');
		tmpForm.method = 'POST';
		tmpForm.action = settings.salesforceW2LUrl;
		tmpForm.target = 'salesforce-target';

		// Prepare form data
		// Remove action
		formData = formData.split('&');
		for (let i = formData.length; i--; ) {
			if (formData[i].indexOf('action=') > -1) {
				formData.splice(i, 1);
				break;
			}
		}

		// Add oid
		formData.push('oid=' + settings.salesforceOid);

		// add fields to tmpForm
		let fields = '';
		for (let i = formData.length; i--; ) {
			let field = formData[i].split('=');
			fields += `<input type="hidden" name="${field[0]}" value="${decodeURIComponent(field[1])}">`;
		}
		tmpForm.innerHTML = fields;
		tmpForm = instance.el.appendChild(tmpForm);

		submitTimeoutId = setTimeout(() => {
			showErrorMessage();
		}, config.submitTimeout);

		tmpForm.submit();
	};

	const validateForm = (jumpToFirstError = true) => {
		let formValid = formValidator.validate();

		inputFields.forEach(field => {
			field.classList.remove('has-error');
		});

		if (!formValid) {
			let errors = formValidator.getErrors();
			console.log(errors);

			for (let i = errors.length; i--; ) {
				let inputWrapper = errors[i].input.closest('[data-ref="ContactForm:input"]');
				inputWrapper.classList.add('has-error');

				inputWrapper.querySelector('[data-ref="Input:errorMessage"]').innerHTML = errors[i].errors[0];
			}

			if (jumpToFirstError) {
				let inputWrapper = errors[0].input.closest('[data-ref="ContactForm:input"]');
				let rect = inputWrapper.getBoundingClientRect();
				let scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
				let viewportHeight = window.innerHeight;
				let upperHalf = {
					top: 20,
					bottom: viewportHeight / 2
				};

				if (rect.top < upperHalf.top || rect.bottom > upperHalf.bottom) {
					try {
						window.scrollTo({
							top: scrollY + rect.top - upperHalf.top,
							left: 0,
							behavior: 'smooth'
						});
					} catch (e) {
						window.scrollTo(0, scrollY + rect.top - upperHalf.top);
					}
				}
			}
		}

		return formValid;
	};

	const onSubmit = e => {
		e.preventDefault();

		if (!validateForm(settings.jumpToFirstError) || submitBtn.disabled) {
			return;
		}

		instance.setState('submitting');
		submitBtn.disabled = true;

		let formData = serialize(form);

		// Post to Freeform plugin
		fetch(form.action.value, {
			method: 'post',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				HTTP_X_REQUESTED_WITH: 'XMLHttpRequest'
			},
			body: new FormData(form)
		})
			.then(response => {
				if (!response.ok) {
					showErrorMessage();
					throw new Error('Form could not be submitted.');
				}

				return response.json();
			})
			.then(response => {
				console.log(response);

				if (response.success && response.finished) {
					if (window.fbq) {
						let userData = {};
						let formProps = Object.fromEntries(new FormData(form));
						if (typeof formProps.email !== 'undefined') {
							userData.em = formProps.email;
						}
						if (typeof formProps.firstName !== 'undefined') {
							userData.fn = formProps.firstName;
						}
						if (typeof formProps.lastName !== 'undefined') {
							userData.ln = formProps.lastName;
						}
						if (typeof formProps.name !== 'undefined') {
							userData.fn = formProps.name.split(' ')[0];
							userData.ln = formProps.name.split(' ')[1];
						}

						if (window.fbq.hasOwnProperty('getState') && window.fbq.getState().pixels.length !== 0) {
							window.fbq.getState().pixels.forEach(pixel => {
								let pixelId = pixel.id;

								if (Object.keys(pixel.userData).length !== 0) {
									window.fbq.init(pixelId, {}); // Reset the pixel Id tracking user data
								}

								window.fbq.init(pixelId, userData);
							});
						}

						window.fbq(
							'track',
							'Lead',
							{},
							{ eventID: form.querySelector('input[name^="freeform_form_handle"]').value }
						);
					}

					// Check if we have data to forward form to salesforce
					if (settings.salesforceW2LUrl && settings.salesforceOid) {
						console.log('forwarding form post to salesforce...');
						postToSalesforce(formData);
					} else {
						showSuccessMessage();
					}
				} else if (response.formErrors.length || (response.errors && Object.keys(response.errors).length)) {
					showErrorMessage();
				}
			});
	};

	const reloadFormParameters = () => {
		fetch(settings.dynamicParametersUrl + '?form=' + settings.formHandle, {
			method: 'get'
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Form ' + settings.formHandle + ' parameters could not be reset.');
				}

				return response.json();
			})
			.then(response => {
				// Locate and update the Hash input
				let formHashInput = form.querySelector('input[name=formHash]');
				if (formHashInput) {
					formHashInput.setAttribute('value', response.hash);
				}

				// Locate and update the JS Honeypot input (if applicable)
				let honeypotInput = form.querySelector('input[name^="freeform_form_handle"]');
				if (honeypotInput) {
					honeypotInput.setAttribute('id', response.honeypot.name);
					honeypotInput.setAttribute('name', response.honeypot.name);
					honeypotInput.value = response.honeypot.hash;
				}

				// Locate and update the CSRF input
				if (response.csrf.name !== '') {
					let csrfInput = form.querySelector('input[name=' + response.csrf.name + ']');
					if (csrfInput) {
						csrfInput.value = response.csrf.value;
					}
				}
			});
	};

	const onChange = e => {
		if (e.target.closest('[data-ref="ContactForm:input"]')) {
			validateForm(false);
		}
	};

	const handleQueryParams = () => {
		const params = new URLSearchParams(window.location.search);

		inputFields.forEach(field => {
			const element = field.querySelector('[name]');

			if (!element) return;

			const name = element.name;

			if (params.has(name)) element.value = params.get(name);
		});
	};

	const bindEvents = () => {
		form.addEventListener('submit', onSubmit);
		form.addEventListener('reset', reloadFormParameters);
		instance.el.addEventListener('change', onChange);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('change', onChange);
		form.removeEventListener('submit', onSubmit);
		form.removeEventListener('reset', reloadFormParameters);
		formValidator.destroy();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		if (typeof settings.jumpToFirstError == 'undefined') {
			settings.jumpToFirstError = true;
		}
		form = instance.ref(config.formRef);
		inputFields = instance.ref(config.inputFieldRef);
		submitBtn = instance.ref(config.submitBtn);
		formValidator = new Pristine(form);

		// Locate and update the Hash input
		let formHashInput = form.querySelector('input[name=formHash]');
		if (formHashInput) {
			formHashInput.setAttribute('value', settings.hash);
		}

		// Locate and update the JS Honeypot input (if applicable)
		let honeypotInput = form.querySelector('input[name^="freeform_form_handle"]');
		if (honeypotInput) {
			honeypotInput.setAttribute('id', settings.honeypot.name);
			honeypotInput.setAttribute('name', settings.honeypot.name);
			honeypotInput.value = settings.honeypot.hash;
		}

		// Locate and update the CSRF input
		if (settings.csrf.name !== '') {
			let csrfInput = form.querySelector('input[name=' + settings.csrf.name + ']');
			if (csrfInput) {
				csrfInput.value = settings.csrf.value;
			}
		}

		if (settings.successMessage && settings.successMessage !== '') {
			instance.ref('successMsg').innerHTML = settings.successMessage;
		}

		bindEvents();
		handleQueryParams();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
