let teasers = document.querySelectorAll('[data-js="TextImage"]');

for (let i = 0; i < teasers.length; i++) {
	if (i % 2 === 1) {
		teasers[i].classList.add('flipped');
	}
}

for (let i = 0; i < teasers.length; i++) {
	teasers[i].classList.add('show');
}
