// Mostly from https://codepen.io/Sirop/pen/jZaQdV

/**
 * Add attribute `data-magnetic` to any DOM element which should have magnetic hover effect.
 * The target element should not have any transforms on it because it will be overridden by this plugin.
 */

import { resizeeventsManager, scrolleventsManager } from 'app/util/events-manager';

// TODO: These config settings should be read from data-attributes
let offsetHoverMax = 1.1;
let offsetHoverMin = 0.8;

let elements;
let pointerX;
let pointerY;

const bindEvents = () => {
	document.addEventListener('mousemove', onMousemove, window.HV.supportsPassive ? { passive: true } : false);
	resizeeventsManager.add(onResize);
	scrolleventsManager.add(onResize);
};

const unbindEvents = () => {
	document.removeEventListener('mousemove', onMousemove);
	resizeeventsManager.remove(onResize);
	scrolleventsManager.remove(onResize);
};

const init = () => {
	elements = Array.from(document.querySelectorAll('[data-magnetic]')).map(el => ({
		rect: el.getBoundingClientRect(),
		el
	}));

	if (elements.length) {
		bindEvents();
	} else {
		unbindEvents();
	}
};

const transformTo = ({ el, x, y, rotation, duration, easing }) => {
	easing = easing || 'ease-out';

	let rad = parseFloat(rotation) * (Math.PI / 180);

	el.style.transition = `transform ${duration}s ${easing}`;
	el.style.transform = `matrix(${Math.cos(rad)}, ${Math.sin(rad)}, ${Math.sin(rad) * -1}, ${Math.cos(
		rad
	)}, ${x}, ${y})`;
};

const onHover = function(el, x, y) {
	transformTo({
		el,
		duration: 0.6,
		x: x * 0.2,
		y: y * 0.2,
		rotation: x * 0.02,
		easing: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)'
	});
};

const onLeave = function(el) {
	transformTo({
		el,
		duration: 0.9,
		x: 0,
		y: 0,
		rotation: 0,
		easing: 'cubic-bezier(0.250, 0.460, 0.450, 0.940)'
	});
};

const doMagnetic = (el, pointerX, pointerY) => {
	el.hover = el.hover === undefined ? false : el.hover;
	let hoverArea = el.hover ? offsetHoverMax : offsetHoverMin;

	// position
	let elPos = {
		x: el.rect.left + el.rect.width / 2,
		y: el.rect.top + el.rect.height / 2
	};

	// comparison
	let x = pointerX - elPos.x;
	let y = pointerY - elPos.y;

	// dist
	let dist = Math.sqrt(x * x + y * y);

	// mutex hover
	let mutHover = false;

	// anim
	if (dist < el.rect.width * hoverArea) {
		mutHover = true;

		if (!el.hover) {
			el.hover = true;
		}
		onHover(el.el, x, y);
	}

	// reset
	if (!mutHover && el.hover) {
		onLeave(el.el);
		el.hover = false;
	}
};

const onResize = () => {
	init();
};

const onMousemove = e => {
	pointerX = e.clientX !== undefined ? e.clientX : e.targetTouches[0].clientX;
	pointerY = e.clientY !== undefined ? e.clientY : e.targetTouches[0].clientY;

	for (let i = elements.length; i--; ) {
		doMagnetic(elements[i], pointerX, pointerY);
	}
};

export default {
	init
};
