/**
 * Sliderv2
 *
 * @selector [data-js="Sliderv2"]
 * @enabled true
 */
import {base} from 'app/util/base';
import Flickity from 'flickity-fade';

const defaults = {
	pageDots: false,
	wrapAround: true,
	on: {
		ready: function () {
			let instance = this;
			toggleNavigationText(instance);
			handleVidePlaybackOnLoad(instance.element);
			window.addEventListener('resize', function () {
				toggleNavigationText(instance);
			});

			let dragged = false
			instance.on('pointerDown', function () {
				dragged = false
			})
			instance.on('pointerMove', function () {
				dragged = true
			})
			instance.on('pointerUp', function () {
				if (dragged === true) {
					return
				}
				toggleVideoPlayback(instance.element);
			})

			// Check adaptiveheight is off
			let slides = instance.element.querySelectorAll('.Sliderv2--slide');
			let maxHeight = 0;
			for (let i = 0; i < slides.length; i++) {
				if (slides[i].clientHeight > maxHeight) {
					maxHeight = slides[i].clientHeight;
				}
			}
			if (maxHeight > 0) {
				instance.element.querySelector('.flickity-viewport').style.height = maxHeight + 'px';
			}
		},
		settle: function () {
		}
	},
	arrowShape:
		'M 43.14015,49.999999 77.065392,16.074757 c 3.675752,-3.675752 3.675752,-9.6421915 0,-13.3179445 v 0 c -3.675753,-3.67575165 -9.642192,-3.67575165 -13.317944,0 L 22.781453,43.722808 c -3.471545,3.471544 -3.471545,9.091716 0,12.554382 l 40.965995,40.965999 c 3.675752,3.675751 9.642191,3.675751 13.317944,0 v 0 c 3.675752,-3.67576 3.675752,-9.642196 0,-13.317948 z'
	// 'M43.3293 50l32.9899-33.2467c3.5744-3.6023 3.5744-9.44938 0-13.05162s-9.3764-3.60224-12.9508 0L23.5319 43.8483c-3.3759 3.4022-3.3759 8.9099 0 12.3033l39.8365 40.1467c3.5744 3.6023 9.3764 3.6023 12.9508 0 3.5744-3.6022 3.5744-9.4493 0-13.0516L43.3293 50z'
};

const config = {
	optionsAttr: 'data-options'
};

const toggleNavigationText = function (instance) {
	if (typeof instance.pageDots === "undefined" || instance.pageDots === null) {
		return;
	}

	for (let i = 0; i < instance.pageDots.dots.length; i++) {
		if (instance.element.parentElement.parentElement.classList.contains("show-navigation-text") &&
			window.outerWidth > 1200) {
			let navigation = instance.cells[i].element.querySelector('.navigation');
			if (navigation) {
				instance.pageDots.dots[i].innerText = ((i + 1) + '. ' +
					instance.cells[i].element.querySelector('.navigation').innerText.trim()).trim();
			} else {
				instance.pageDots.dots[i].innerText = (i + 1) + '. ';
			}
		} else {
			instance.pageDots.dots[i].innerText = '';
		}
	}
}

const handleVidePlaybackOnLoad = function (element) {
	/*
	let source = element.querySelector('video source');
	if (typeof source === "undefined" || source === null) {
		return;
	}

	if (typeof Cookies.get('video_played') === "undefined") {
		// First time user, don't do anything, let the video play and record the video src in the cookie
		Cookies.set('video_played', source.src);
		return;
	}

	if (Cookies.get('video_played') !== source.src) {
		// User already seen a video but not this one
		Cookies.set('video_played', source.src);
		return;
	}
	*/

	// User has already seen the exact video
	pauseVideo(element);
}

const toggleVideoPlayback = function (element) {
	if (element.querySelector('video') && element.querySelector('video').paused) {
		playVideo(element);
	} else if (element.querySelector('video')) {
		pauseVideo(element);
	}
}

const pauseVideo = function (element) {
	let pause = element.querySelector('.FullscreenSlide--video-control .video-pause');
	if (pause) {
		pause.style.display = "none";
		pause.style.opacity = 1;
	}

	let play = element.querySelector('.FullscreenSlide--video-control .video-play');
	if (play) {
		play.style.display = "block";
	}
	let video = element.querySelector('video');
	if (video) {
		video.pause();
	}
}

const playVideo = function (element) {
	let pause = element.querySelector('.FullscreenSlide--video-control .video-pause');
	if (pause) {
		pause.style.display = "block";
		pause.style.transition = "0.3s";
		setTimeout(() => {
			pause.style.opacity = 0;
		}, 2000);
	}

	let play = element.querySelector('.FullscreenSlide--video-control .video-play');
	if (play) {
		play.style.display = "none";
	}
	let video = element.querySelector('video');
	if (video) {
		video.play();
	}
}

export default function Sliderv2() {
	// Private vars
	const instance = {};
	let settings = {};
	let groupCells = 0;
	let fade = false;
	let flickityInstance;
	let intersectionObserver;

	// Private methods
	const onObserve = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setTimeout(() => {
					flickityInstance.resize();
				}, 200);
			}
		});
	};

	const bindEvents = () => {
		intersectionObserver.observe(instance.el);

		window.addEventListener('resize', function () {
			if (groupCells === 0) {
				return;
			}

			if (window.outerWidth >= 768) {
				settings.groupCells = groupCells;
				settings.fade = fade;
			} else {
				settings.groupCells = 0;
				settings.fade = false;
			}

			flickityInstance = new Flickity(instance.el, settings);
		});

		window.addEventListener('scroll', function () {
			let fullScreenSlider = document.querySelector('.FullscreenSlider');
			if (fullScreenSlider && fullScreenSlider.length && window.scrollY >= window.innerHeight) {
				// Pause video when out of view to be able to load other images
				pauseVideo(fullScreenSlider);
			}
		});
	};

	const unbindEvents = () => {
		intersectionObserver.disconnect();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));
		
		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		if (options.initialIndex && options.initialIndex > 0 && window.outerWidth <= 768) {
			// If initial index is set, disable it on mobile
			options.initialIndex = 0;
		}

		settings = Object.assign({}, defaults, options);

		groupCells = options.groupCells;
		fade = options.fade;
		if (window.outerWidth >= 768) {
			settings.groupCells = groupCells;
			settings.fade = fade;
		} else {
			settings.groupCells = 0;
			settings.fade = false;
		}

		flickityInstance = new Flickity(instance.el, settings);
		intersectionObserver = new IntersectionObserver(onObserve, {threshold: 0.33});

		bindEvents();

		console.log(options);
		return instance;
	};

	instance.destroy = () => {
		flickityInstance.destroy();
		unbindEvents();
	};

	return instance;
}
