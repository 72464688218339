/**
 * SocialMediaFeed
 *
 * @selector [data-js="SocialMediaFeed"]
 * @enabled true
 */
import { base } from 'app/util/base';
import * as FeedManager from 'app/modules/FeedsManager';

const defaults = {
	twitchClientId: '',
	twitchUser: '',
	curatorFeedName: '',
	curatorApiKey: '' // Base64 encoded API key
};

const config = {
	optionsAttr: 'data-options',
	twitchRef: 'twitch',
	igRef: 'instagram',
	fbRef: 'facebook'
};

export default function SocialMediaFeed() {
	// Private vars
	const instance = {};
	let settings = {};
	let twitchContainer;
	let igContainers;
	let fbContainers;
	let intersectionObserver;
	let twitchLiveStreams;
	let twitchVideos;
	let curatorPosts;
	let hasTwitch = false;

	// Private methods
	const onObserve = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				console.log('target el:', entry.target);

				switch (entry.target.getAttribute('data-ref').split(':')[1]) {
					case 'twitch':
						FeedManager.setTwitchIframe({
							container: twitchContainer,
							userName: settings.twitchUser,
							liveStreams: twitchLiveStreams,
							videos: twitchVideos
						});
						intersectionObserver.unobserve(twitchContainer);
						break;

					case 'instagram':
						FeedManager.setInstagramPreview({
							container: entry.target,
							postsObj: curatorPosts
						});

						intersectionObserver.unobserve(entry.target);
						break;

					case 'instagram-w-hover':
						FeedManager.setInstagramPreviewWithHover({
							container: entry.target,
							postsObj: curatorPosts
						});

						intersectionObserver.unobserve(entry.target);
						break;

					case 'facebook':
						FeedManager.setFacebookPreview({
							container: entry.target,
							postsObj: curatorPosts
						});

						intersectionObserver.unobserve(entry.target);
				}
			}
		});
	};

	const unbindEvents = () => {
		intersectionObserver.disconnect();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		if (settings.twitchUser) {
			hasTwitch = true;
		}

		intersectionObserver = new IntersectionObserver(onObserve, { threshold: 0 });
		twitchContainer = instance.ref(config.twitchRef);
		igContainers = instance.ref(config.igRef, true) || instance.ref(config.igRef + '-w-hover', true);
		fbContainers = instance.ref(config.fbRef, true);

		let feedPromises = [
			FeedManager.getCuratorFeed({ apiKey: atob(settings.curatorApiKey), feedName: settings.curatorFeedName })
		];

		if (hasTwitch) {
			feedPromises.push(
				FeedManager.getTwitchStreams({ userName: settings.twitchUser, clientId: settings.twitchClientId })
			);
		}

		Promise.all(feedPromises).then(result => {
			curatorPosts = result[0];

			if (hasTwitch) {
				twitchLiveStreams = result[1][0].data;
				twitchVideos = result[1][1].data;
			}

			// Start observing the feed containers

			if (hasTwitch) {
				intersectionObserver.observe(twitchContainer);
			}

			if (fbContainers) {
				fbContainers.forEach(fb => {
					intersectionObserver.observe(fb);
				});
			}

			if (igContainers) {
				igContainers.forEach(ig => {
					intersectionObserver.observe(ig);
				});
			}
		});

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
