/**
 * InfoTeaserOffer
 *
 * @selector [data-js="InfoTeaserOffer"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { computePosition, autoUpdate, flip, shift, offset, arrow, size } from '@floating-ui/dom';

export default function InfoTeaserOffer() {
	// Private vars
	const instance = {};
	let button;
	let tooltip;
	let arrowElement;
	let cleanup;

	const isMobile = () => window.outerWidth <= 500;

	const getZoom = () => {
		const ratio = window.devicePixelRatio;

		if (isMobile() || ratio < 1.09) return 0;

		if (ratio >= 1.5) {
			return 0.665;
		}
		if (ratio >= 1.25) {
			return 0.8;
		}
		if (ratio >= 1.2) {
			return 0.85;
		}
		if (ratio >= 1.1) {
			return 0.91;
		}
		if (ratio >= 1.09) {
			return 0.95;
		}
	};

	const updateTooltip = () => {
		computePosition(button, tooltip, {
			placement: 'top-start',
			middleware: [
				offset(!getZoom() || isMobile() ? 6 : -6),
				flip(),
				shift({ padding: 10 }),
				arrow({ element: arrowElement }),
				size({
					apply({ availableWidth, availableHeight, elements }) {
						Object.assign(elements.floating.style, {
							maxWidth: `${Math.max(0, availableWidth)}px`,
							maxHeight: `${Math.max(0, availableHeight)}px`
						});
					}
				})
			]
		}).then(({ x, y, placement, middlewareData }) => {
			const zoom = getZoom();

			Object.assign(tooltip.style, {
				left: `${zoom ? x / zoom : x}px`,
				top: `${zoom ? y / zoom : y}px`
			});

			// Accessing the data
			const { x: arrowX, y: arrowY } = middlewareData.arrow;

			const staticSide = {
				top: 'bottom',
				right: 'left',
				bottom: 'top',
				left: 'right'
			}[placement.split('-')[0]];

			Object.assign(arrowElement.style, {
				left: arrowX != null ? `${zoom ? arrowX / zoom + 1 : arrowX}px` : '',
				top: arrowY != null ? `${zoom ? arrowY / zoom : arrowY}px` : '',
				right: '',
				bottom: '',
				[staticSide]: '-4px'
			});
		});
	};

	const showTooltip = () => {
		tooltip.style.display = 'block';
		updateTooltip();
	};

	const hideTooltip = () => {
		tooltip.style.display = '';
	};

	const bindEvents = () => {
		if (!tooltip || !button) return;

		document.body.append(tooltip);

		[
			['mouseenter', showTooltip],
			['mouseleave', hideTooltip],
			['touchstart', showTooltip],
			['focus', showTooltip],
			['blur', hideTooltip]
		].forEach(([event, listener]) => {
			button.addEventListener(event, listener);
		});

		cleanup = autoUpdate(button, tooltip, updateTooltip, {
			ancestorScroll: true,
			ancestorResize: true,
			elementResize: true,
			layoutShift: true,
			animationFrame: true
		});
	};

	const unbindEvents = () => {
		if (!tooltip || !button) return;

		[
			['mouseenter', showTooltip],
			['mouseleave', hideTooltip],
			['touchstart', showTooltip],
			['focus', showTooltip],
			['blur', hideTooltip]
		].forEach(([event, listener]) => {
			button.removeEventListener(event, listener);
		});

		cleanup();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		button = document.querySelector('#infoTeaserOfferTooltipButton');
		tooltip = document.querySelector('#infoTeaserOfferTooltip');
		arrowElement = document.querySelector('#infoTeaserOfferTooltipArrow');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
