/**
 * Logo
 *
 * @selector [data-js="Button"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { scrolleventsManager } from 'app/util/events-manager';

const config = {
	optionsAttr: 'data-options',
	listRef: 'list',
	currentRef: 'current'
};

export default function Button() {
	const instance = {};
	let list;
	let timeoutId;

	const close = () => {
		timeoutId = setTimeout(() => list.setAttribute('data-state', ''), 300);
	};

	const open = () => {
		list.setAttribute('data-state', 'open');
	};

	const toggle = () => {
		if (list.getAttribute('data-state') === 'open') {
			close();
		} else {
			open();
		}
	};

	const clearCurrentTimeout = () => timeoutId && clearTimeout(timeoutId);

	const onLeave = () => {
		clearCurrentTimeout();
		close();
	};

	const onEnter = () => {
		timeoutId && clearCurrentTimeout();
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', toggle);
		instance.el.addEventListener('mouseleave', onLeave);
		instance.el.addEventListener('mouseenter', onEnter);
		scrolleventsManager.add(close);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', toggle);
		instance.el.addEventListener('mouseleave', onLeave);
		instance.el.addEventListener('mouseenter', onEnter);
		scrolleventsManager.remove(close);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		list = instance.ref(config.listRef);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
