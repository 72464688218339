// https://codetheory.in/controlling-the-frame-rate-with-requestanimationframe/

export const throttler = (func, fps = 60) => {
	let now;
	let then = Date.now();
	let interval = 1000 / fps;
	let delta;
	let rId;

	function draw() {
		rId = requestAnimationFrame(draw);

		now = Date.now();
		delta = now - then;

		if (delta > interval) {
			then = now - (delta % interval);

			func();
		}
	}

	return {
		get fps() {
			return fps;
		},
		set fps(value) {
			fps = value;
		},
		start: draw,
		stop: () => {
			cancelAnimationFrame(rId);
		}
	};
};
