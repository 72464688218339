/**
 * CorporateSection05
 *
 * @selector [data-js="CorporateSection05"]
 * @enabled true
 */
import { base } from 'app/util/base';
import Pristine from 'pristinejs/dist/pristine';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
	formRef: 'form',
	submitBtn: 'submitBtn',
	inputFieldRef: 'input',
	contentRef: 'content',
	submitTimeout: 30 * 1000,
	forcedSubmitDelay: 500,
	successMessageRead: 4000
};

export default function CorporateSection05() {
	// Private vars
	const instance = {};
	let settings = {};
	let form;
	let submitBtn;
	let inputFields;
	let formValidator;

	const onSubmit = event => {
		event.preventDefault();

		if (!validateForm()) return;

		instance.setState('submitting');
		submitBtn.disabled = true;

		let formData = new FormData(form);

		// Post to Freeform plugin
		fetch(form.action.value, {
			method: 'post',
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				HTTP_X_REQUESTED_WITH: 'XMLHttpRequest'
			},
			body: formData
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Form could not be submitted.');
				}

				return response.json();
			})
			.then(response => {
				if (response.success && response.finished) {
					showSuccessMessage();
				}
			})
			.finally(() => {
				instance.setState('submitted');
				submitBtn.disabled = false;
			});
	};

	const showSuccessMessage = () => {
		if (!(settings.successTitle || settings.successSubtitle)) return;

		document.querySelector('div[data-ref="CorporateSection05:content"').remove();
	};

	const validateForm = (jumpToFirstError = true) => {
		let formValid = formValidator.validate();

		inputFields.forEach(field => {
			field.classList.remove('has-error');
		});

		if (!formValid) {
			form.removeEventListener('change', validateForm);

			let errors = formValidator.getErrors();

			for (let i = errors.length; i--; ) {
				let inputWrapper = errors[i].input.closest('[data-ref="CorporateSection05:input"]');
				inputWrapper.classList.add('has-error');

				inputWrapper.querySelector('[data-ref="Input:errorMessage"]').innerHTML = errors[i].errors[0];
			}

			if (jumpToFirstError) {
				let inputWrapper = errors[0].input.closest('[data-ref="CorporateSection05:input"]');
				let rect = inputWrapper.getBoundingClientRect();
				let scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
				let viewportHeight = window.innerHeight;
				let upperHalf = {
					top: 20,
					bottom: viewportHeight / 2
				};

				if (rect.top < upperHalf.top || rect.bottom > upperHalf.bottom) {
					try {
						window.scrollTo({
							top: scrollY + rect.top - upperHalf.top,
							left: 0,
							behavior: 'smooth'
						});
					} catch (e) {
						window.scrollTo(0, scrollY + rect.top - upperHalf.top);
					}
				}
			}

			form.addEventListener('change', validateForm);
		} else {
			form.removeEventListener('change', validateForm);
		}

		return formValid;
	};

	const bindEvents = () => {
		form.addEventListener('submit', onSubmit);
		submitBtn.addEventListener('click', onSubmit);
	};

	const unbindEvents = () => {
		form.removeEventListener('submit', onSubmit);
		submitBtn.addEventListener('click', onSubmit);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		if (typeof settings.jumpToFirstError == 'undefined') {
			settings.jumpToFirstError = true;
		}
		form = instance.ref(config.formRef);
		submitBtn = instance.ref(config.submitBtn);
		inputFields = instance.ref(config.inputFieldRef);
		formValidator = new Pristine(form);

		// Locate and update the Hash input
		let formHashInput = form.querySelector('input[name=formHash]');
		if (formHashInput) {
			formHashInput.setAttribute('value', settings.hash);
		}

		// Locate and update the JS Honeypot input (if applicable)
		let honeypotInput = form.querySelector('input[name^="freeform_form_handle"]');
		if (honeypotInput) {
			honeypotInput.setAttribute('id', settings.honeypot.name);
			honeypotInput.setAttribute('name', settings.honeypot.name);
			honeypotInput.value = settings.honeypot.hash;
		}

		// Locate and update the CSRF input
		if (settings.csrf.name !== '') {
			let csrfInput = form.querySelector('input[name=' + settings.csrf.name + ']');
			if (csrfInput) {
				csrfInput.value = settings.csrf.value;
			}
		}

		if (settings.successMessage && settings.successMessage !== '') {
			instance.ref('successMsg').innerHTML = settings.successMessage;
		}

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
