const twitchIframe = ({ channelId, videoId, autoplay }) => `
<iframe
    src="https://player.twitch.tv/?${channelId ? 'channel=' + channelId : 'video=v' + videoId}&muted=true&autoplay=${
	autoplay ? 'true' : 'false'
}"
    frameborder="0"
    scrolling="no"
    allowfullscreen>
</iframe>
`;

const facebookPreview = ({ url, text, userImgSrc, userName, date, formatDate }) => `
<a class="preview facebook" href="${url}" data-ref="Post:preview" target="_blank" rel="noreferrer noopener">
	<article>
		<header class="preview-header">
			<img src="${userImgSrc}" alt=""/>
			<div>
				<p class="color-blue-neon tx-body-s">${userName}</p>
				<time class="tx-body-s" datetime="${date}">${formatDate}</time>
			</div>
		</header>
		<blockquote class="richtext">${text}</blockquote>
	</article>
</a>
`;

const instagramPreview = ({ imageSrc, text, url }) => `
<a class="preview instagram" href="${url}" data-ref="Post:preview" target="_blank" rel="noreferrer noopener">
	<article>
		<span class="visuallyhidden">${text}</span>
		<img class="SocialMediaFeed--igImg" src="${imageSrc}" alt=""/>
	</article>
</a>
`;

const instagramPreviewWithHover = ({ imageSrc, text, url, userImage, userFullName, frameHtml }) => `
<a class="preview with-hover instagram" href="${url}" data-ref="Post:preview" target="_blank" rel="noreferrer noopener">
	<article>
		<div class="on-hover">
			<div class="header">
				<div class="SocialMediaFeed--igUserImage">
					<img src="${userImage}" alt=""/>
					${frameHtml}
				</div>
				<div class="SocialMediaFeed--igUserName">${userFullName}</div>
			</div>
			<div class="SocialMediaFeed--igText">${text}</div>
		</div>
		<img class="SocialMediaFeed--igImg" src="${imageSrc}" alt=""/>
	</article>
</a>
`;

export const getCuratorFeed = ({ apiKey, feedName }) =>
	new Promise(resolve => {
		const getFeeds = () => {
			return fetch(`https://api.curator.io/v1/feeds?api_key=${apiKey}`)
				.then(response => response.json())
				.then(json => {
					return json;
				});
		};

		const getPosts = feedId => {
			return fetch(`https://api.curator.io/v1/feeds/${feedId}/posts`)
				.then(response => response.json())
				.then(json => {
					return json;
				});
		};

		getFeeds().then(feeds => {
			for (let i = feeds.length; i--; ) {
				console.log('feed', feeds[i]);

				if (feeds[i].name === feedName) {
					getPosts(feeds[i].id).then(posts => {
						resolve(posts);
					});
				}
			}
		});
	});

export const getTwitchStreams = ({ userName, clientId }) =>
	new Promise(resolve => {
		console.log(userName, clientId);

		let userId;
		let fetchOptions = {
			method: 'GET',
			headers: {
				'Client-ID': clientId
			}
		};

		const getUser = () => {
			return fetch(`https://api.twitch.tv/helix/users?login=${userName}`, fetchOptions)
				.then(response => response.json())
				.then(json => {
					// We should get an array with only one user object.
					return json.data[0];
				});
		};

		const getLiveStreams = () => {
			return fetch(`https://api.twitch.tv/helix/streams?user_id=${userId}`, fetchOptions)
				.then(response => response.json())
				.then(json => {
					return json;
				});
		};

		const getVideos = () => {
			return fetch(`https://api.twitch.tv/helix/videos?user_id=${userId}&sort=time`, fetchOptions)
				.then(response => response.json())
				.then(json => {
					return json;
				});
		};

		getUser().then(user => {
			userId = user.id;

			Promise.all([getLiveStreams(), getVideos()]).then(results => {
				resolve(results);
			});
		});
	});

export const setTwitchIframe = ({ liveStreams, videos, container, userName }) => {
	console.log(liveStreams, videos);

	if (liveStreams.length) {
		// Get the first live stream
		for (let i = 0; i < liveStreams.length; i++) {
			if (liveStreams[i].type === 'live') {
				container.innerHTML = twitchIframe({ channelId: userName, autoplay: true });
				container.setAttribute('data-state', 'ready');
				return;
			}
		}
	}

	// No live stream is available. Just show the latest video
	if (videos.length) {
		container.innerHTML = twitchIframe({ videoId: videos[0].id, autoplay: false });
		container.setAttribute('data-state', 'ready');
	} else {
		// No video available... what to do?
	}
};

export const setInstagramPreview = ({ container, postsObj }) => {
	let idx = Number(container.getAttribute('data-index'));

	// Get nth instagram post according to index of container
	let post = postsObj['posts'].filter(p => p['network_name'] === 'Instagram')[idx];

	// Medium sized image should be ok.
	let imageSrc = post.image.replace('size=l', 'size=m');
	let text = post.text;
	let url = post.url;
	console.log('instagram post: ', post);

	container.innerHTML = instagramPreview({ imageSrc, text, url });
	container.setAttribute('data-state', 'ready');
};

export const setInstagramPreviewWithHover = ({container, postsObj}) => {
	let idx = Number(container.getAttribute('data-index'));

	// Get nth instagram post according to index of container
	let post = postsObj['posts'].filter(p => p['network_name'] === 'Instagram')[idx];

	// Medium sized image should be ok.
	let imageSrc = post.image.replace('size=l', 'size=m');
	let userImage = post.user_image;
	let userFullName = post.user_full_name;
	let text = post.text.length > 170 ? (post.text.substring(0, 170) + ' ...') : post.text;
	let url = post.url;
	let frameSvg = container.querySelector('.instagram-frame-svg');
	let frameHtml = container.querySelector('.instagram-frame-svg').innerHTML;
	frameSvg.remove();
	console.log('instagram post: ', post);

	container.innerHTML = instagramPreviewWithHover({imageSrc, text, url, userImage, userFullName, frameHtml});
	container.setAttribute('data-state', 'ready');
};

export const setFacebookPreview = ({ container, postsObj }) => {
	let idx = Number(container.getAttribute('data-index'));

	// Get nth instagram post according to index of container
	let post = postsObj['posts'].filter(p => p['network_name'] === 'Facebook')[idx];
	let text = post['text'];
	let url = post['url'];
	let userImgSrc = post['user_image'];
	let userName = post['user_full_name'];
	let date = post['source_created_at'];

	text = text.replace(/\n\n/g, '</p><p>');
	text = text.replace(/\n/g, '<br>');
	text = `<p>${text}</p>`;

	date = new Date(date);

	console.log('facebook post: ', post);

	container.innerHTML = facebookPreview({
		url,
		text,
		userImgSrc,
		userName,
		date,
		formatDate: date.toLocaleString('de', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		})
	});
	container.setAttribute('data-state', 'ready');
};
