let teasers = document.querySelectorAll('[data-js="TextImageTeaser"]');

for (let i = 0; i < teasers.length; i++) {
	if (
		teasers[i].nextElementSibling &&
		teasers[i].nextElementSibling.hasAttribute('data-js') &&
		teasers[i].nextElementSibling.getAttribute('data-js') === 'TextImageTeaser'
	) {
		teasers[i].classList.add('has-next-sibling');
	}

	if (i % 2 === 1) {
		teasers[i].classList.add('flipped');
	}
}

for (let i = 0; i < teasers.length; i++) {
	teasers[i].classList.add('show');
}
