/**
 * PictureContent04
 *
 * @selector [data-js="PictureContent04"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function PictureContent04() {
	// Private vars
	const instance = {};

	const getButton = () => instance.el.querySelector('#pictureContent04ShowMore');

	const getHiddenList = () => instance.el.querySelector('#pictureContent04HiddenList');

	const onButtonClick = () => {
		const hiddenList = getHiddenList();
		const button = getButton();

		hiddenList.setAttribute('data-hidden', '0');

		if (button) {
			button.remove();
		}
	};

	const bindEvents = () => {
		const button = getButton();

		if (button) {
			button.addEventListener('click', onButtonClick);
		}
	};

	const unbindEvents = () => {
		const button = getButton();

		if (button) {
			button.removeEventListener('click', onButtonClick);
		}
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
