/**
 * CompleteCookieConsent
 *
 * @selector [data-js="CompleteCookieConsent"]
 * @enabled true
 */
import {base} from 'app/util/base';
import {serialize} from "../../../assets/js/app/util/form-serialize";

export default function CompleteCookieConsent() {
	// Private vars
	const instance = {};

	let form;
	let accordion;
	let accordionHeaders;
	let acceptBtn;
	let declineAllBtn;
	let showDetailsBtn;

	// Private methods
	const bindEvents = () => {


		acceptBtn.addEventListener('click', function () {
			let checkboxes = form.querySelectorAll('input[type=checkbox]');
			updateConsent(checkboxes);
			form.dispatchEvent(new CustomEvent("submit", {"bubbles": true, "cancelable": true}));
		})

		declineAllBtn.addEventListener('click', function () {
			let checkboxes = form.querySelectorAll('input[type=checkbox]');
			for (let i = 0; i < checkboxes.length; ++i) {
				let element = checkboxes[i];
				if (element.required === false) {
					element.checked = false;
				}
			}
			updateConsent(checkboxes);
			form.dispatchEvent(new CustomEvent("submit", {"bubbles": true, "cancelable": true}));
		})

		showDetailsBtn.addEventListener('click', function () {
			let container = accordion;
			if (!container.classList.contains('active')) {
				container.classList.add('active');
				instance.ref("show-details").style.display = 'none';
				instance.ref("hide-details").style.display = 'block';
			} else {
				container.classList.remove('active');
				instance.ref("show-details").style.display = 'block';
				instance.ref("hide-details").style.display = 'none';
			}
		})

		for (let i = 0; i < accordionHeaders.length; ++i) {
			let element = accordionHeaders[i];
			element.addEventListener('click', function (event) {
				if (event.target.tagName !== "DIV") {
					return;
				}
				event.preventDefault();

				let container = event.target;

				if (!container.classList.contains('active')) {
					container.classList.add('active');

					let containerContent = container.nextElementSibling;
					containerContent.style.visibility = 'hidden';
					containerContent.style.height = 'auto';

					let height = containerContent.clientHeight + 'px';

					containerContent.style.height = '0px';
					containerContent.style.visibility = 'visible';

					setTimeout(function () {
						containerContent.style.height = height;
					}, 0);
				} else {
					let containerContent = container.nextElementSibling;
					containerContent.style.height = '0px';

					containerContent.addEventListener('transitionend', function () {
						container.classList.remove('active');
					}, {
						once: true
					});
				}
			});
		}

		form.addEventListener('submit', function (e) {
			e.preventDefault();
			instance.setState('submitting');

			let formData = serialize(form);
			console.log('form data: ', formData);


			instance.el.style.height = instance.el.clientHeight + 'px';
			setTimeout(function () {
				instance.el.style.height = '0px';
				// containerContent.style.height = height;
			}, 0);

			fetch(form.action.value, {
				method: 'post',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'X-Requested-With': 'XMLHttpRequest',
					HTTP_X_REQUESTED_WITH: 'XMLHttpRequest'
				},
				body: formData
			})
				.then(response => {
					console.log(response);
					window.location.reload();
				});
		})
	};

	const updateConsent = (checkboxes) => {
		let result = {};

		let granted = [];
		let denied = [];
		for (let i = 0; i < checkboxes.length; ++i) {
			let element = checkboxes[i];
			if (element.checked) {
				if (element.value === "necessary") {
					granted.push('security_storage', 'functionality_storage');
				} else if (element.value === "functional") {
					granted.push('personalization_storage');
				} else if (element.value === "statistics") {
					granted.push('analytics_storage');
				} else if (element.value === "marketing") {
					granted.push('ad_storage');
				}
			} else {
				if (element.value === "necessary") {
					denied.push('security_storage', 'functionality_storage');
				} else if (element.value === "preferences") {
					denied.push('personalization_storage');
				} else if (element.value === "statistics") {
					denied.push('analytics_storage');
				} else if (element.value === "marketing") {
					denied.push('ad_storage');
				}
			}
		}
		granted.forEach(consent => {
			result[consent + ''] = "granted";
		});
		denied.forEach(consent => {
			result[consent + ''] = "denied";
		});

		console.log(result);
		if (typeof gtag != "undefined") {
			// eslint-disable-next-line no-undef
			gtag('consent', 'update', result);
		}
	}

	const unbindEvents = () => {
		form.removeEventListener('submit');

		for (let i = 0; i < accordionHeaders.length; ++i) {
			let element = accordionHeaders[i];
			element.removeEventListener('click');
		}
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		accordion = instance.ref("accordion");

		accordionHeaders = instance.ref("accordion_item_header");
		console.log(accordionHeaders);
		if (typeof accordionHeaders === "undefined") {
			// We can't load anything without elements
			return;
		}

		form = instance.ref('form');
		acceptBtn = instance.ref('accept');
		declineAllBtn = instance.ref('decline');
		showDetailsBtn = instance.ref('details');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
