/**
 * LangSelect
 *
 * @selector [data-js="LangSelect"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { resizeeventsManager } from 'app/util/events-manager';
import { clearStyles, setStyles } from 'app/util/dom-helpers';
import { width as vpWidth } from 'app/util/viewport';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	overlayMargin: 16
};

const config = {
	optionsAttr: 'data-options',
	listRef: 'list',
	currentRef: 'current'
};

export default function LangSelect() {
	// Private vars
	const instance = {};
	let settings = {};
	let currentBtn;
	let list;

	// Private methods
	const positionOverlay = () => {
		clearStyles(list, ['left']);

		let currentBtnRect = currentBtn.getBoundingClientRect();
		let listRect = list.getBoundingClientRect();
		let left = listRect.width / -2 + currentBtnRect.width / 2;

		if (listRect.left + left + listRect.width + settings.overlayMargin >= vpWidth()) {
			left = left - (listRect.left + left + listRect.width + settings.overlayMargin - vpWidth());
		}

		setStyles(list, {
			left: left + 'px'
		});
	};

	const close = () => {
		instance.setState('closed');
	};

	const open = () => {
		setStyles(list, {
			opacity: 0,
			display: 'block'
		});

		positionOverlay();

		clearStyles(list, ['opacity', 'display']);

		instance.setState('open');
	};

	const toggle = () => {
		if (instance.getState() === 'open') {
			close();
		} else {
			open();
		}
	};

	const onResize = () => {
		if (instance.getState() === 'open') {
			positionOverlay();
		}
	};

	const onClick = e => {
		if (e.target.closest(instance.refSelector(config.currentRef))) {
			toggle();
			e.preventDefault();
		}
	};

	const bindEvents = () => {
		instance.el.addEventListener('click', onClick);
		resizeeventsManager.add(onResize);

		pubsub.on('Header.hide-top', close);
	};

	const unbindEvents = () => {
		instance.el.removeEventListener('click', onClick);
		resizeeventsManager.remove(onResize);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		currentBtn = instance.ref(config.currentRef);
		list = instance.ref(config.listRef);

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
