/**
 * Logo
 *
 * @selector [data-js="Logo"]
 * @enabled true
 */
import { base } from 'app/util/base';
import { throttler } from 'app/util/throttler';
import { animateProps, animateValue } from 'app/util/animate';
import { easeOutQuad } from 'app/util/easings';
import { clearStyles } from 'app/util/dom-helpers';
import { pubsub } from 'app/modules/pubsub';

const defaults = {
	framesCount: 32,
	delay: 100
};

const config = {
	optionsAttr: 'data-options'
};

export default function Logo() {
	// Private vars
	const instance = {};
	let settings = {};
	let ru;
	let racing;
	let unleashed;

	// Private methods
	const ruAnim = () => {
		let i = 0;

		const t = throttler(() => {
			if (i === settings.framesCount + 1) {
				t.stop();
				return;
			}

			ru.setAttribute('data-sprite', i);
			i++;
		}, 36);

		t.start();
	};

	const textAnim = cb => {
		// Text RACING
		animateValue({
			duration: 500,
			start: -8,
			end: 0,
			easing: easeOutQuad,
			onUpdate: data => {
				// Because IE doesn't support css transforms on svg elements
				racing.setAttribute('transform', `translate(${data})`);
			},
			delay: 400
		});

		animateProps({
			el: racing.querySelectorAll('path'),
			duration: 100,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			],
			easing: easeOutQuad,
			delay: 400,
			stagger: 50
		});

		// Text UNLEASHED
		animateProps({
			el: unleashed.querySelectorAll('path'),
			duration: 50,
			props: [
				{
					propName: 'opacity',
					start: 0,
					end: 1
				}
			],
			easing: easeOutQuad,
			delay: 600,
			stagger: 50
		});

		animateValue({
			duration: 600,
			start: -8,
			end: 0,
			easing: easeOutQuad,
			onUpdate: data => {
				// Because IE doesn't support css transforms on svg elements
				unleashed.setAttribute('transform', `translate(${data})`);
			},
			delay: 600,
			onComplete: () => {
				// All animations are finished.
				instance.el.classList.add('default');
				instance.el.classList.remove('animated');

				// Clean up elements
				racing.removeAttribute('transform');
				unleashed.removeAttribute('transform');
				clearStyles([...racing.querySelectorAll('path'), ...unleashed.querySelectorAll('path')], ['opacity']);

				cb && cb();
			}
		});
	};

	const onAnimate = data => {
		instance.el.classList.remove('default');
		instance.el.classList.add('animated');

		ruAnim();
		textAnim(() => {
			data.onComplete();
			pubsub.off('Logo.animate', onAnimate);
		});
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		ru = instance.ref('ru');
		racing = instance.el.querySelector('.Logo--racing'); // Because svg;
		unleashed = instance.el.querySelector('.Logo--unleashed'); // Because svg;

		if (instance.el.classList.contains('animated')) {
			setTimeout(() => {
				ruAnim();
				textAnim();
			}, settings.delay);
		}

		pubsub.on('Logo.animate', onAnimate);

		return instance;
	};

	instance.destroy = () => {};

	return instance;
}
