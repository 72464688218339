/**
 * NextArticleTeaser
 *
 * @selector [data-js="NextArticleTeaser"]
 * @enabled true
 */
import { base } from 'app/util/base';

const defaults = {
	observerThreshold: 0.5,
	initialDelay: 300
};

const config = {
	optionsAttr: 'data-options'
};

export default function NextArticleTeaser() {
	// Private vars
	const instance = {};
	let settings = {};
	let intersectionObserver;
	let image;

	// Private methods
	const animateIn = () => {
		if (image.classList.contains('lazyloaded')) {
			instance.setState('visible');
		} else {
			requestAnimationFrame(animateIn);
		}
	};

	const onObserve = entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				animateIn();
				intersectionObserver.disconnect();
			}
		});
	};

	const bindEvents = () => {
		intersectionObserver.observe(instance.el);
	};

	const unbindEvents = () => {
		intersectionObserver.disconnect();
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);
		image = instance.ref('image');
		intersectionObserver = new IntersectionObserver(onObserve, {
			threshold: settings.observerThreshold
		});

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
