/**
 * VideoCta
 *
 * @selector [data-js="VideoCta"]
 * @enabled true
 */
import {base} from 'app/util/base';

const defaults = {};

const config = {
	optionsAttr: 'data-options',
	playerRef: '.embed-player'
};

export default function VideoCta() {
	// Private vars
	const instance = {};
	let settings = {};
	// eslint-disable-next-line no-unused-vars
	let player = false;
	let playerEl = false;
	let YT = false;
	let ratio = (9 / 16);

	const insertAfter = (newNode, referenceNode) => {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	const loadGoogleScript = () => {
		const id = 'youtube-iframe-api';
		const src = 'https://www.youtube.com/iframe_api';

		let scripts = document.getElementsByTagName('script')
		const lastJs = scripts[scripts.length - 1];

		// Prevent script from loading twice
		if (document.getElementById(id)) {
			return;
		}

		const js = document.createElement('script');
		js.id = id;
		js.src = src;
		js.async = true;
		js.defer = true;
		insertAfter(js, lastJs);
	}

	// Public methods
	instance.init = element => {
		instance.el = element;
		console.log(element);
		Object.assign(instance, base(instance));

		// Get options from element. These will override default settings
		let options = {};
		if (instance.el.hasAttribute(config.optionsAttr)) {
			options = JSON.parse(instance.el.getAttribute(config.optionsAttr));
		}

		settings = Object.assign({}, defaults, options);

		playerEl = instance.el.querySelector(config.playerRef);
		if (typeof playerEl === "undefined") {
			// We can't load anything without an element
			return;
		}

		// This function creates an <iframe> (and YouTube player) after the API code downloads.
		window.onYouTubeIframeAPIReady = () => {
			YT = window.YT;
			let media = playerEl.parentElement.getBoundingClientRect();

			// calculate inverse ratio, but don't allow higher than 16/9
			ratio = Math.min(media['height'] / media['width'], 9 / 16)
			playerEl.parentElement.setAttribute('style', '--ratio: ' + ratio + ';');

			player = new YT.Player(playerEl.id, {
				height: media['width'] * ratio,
				width: media['width'],
				videoId: settings.videoId,
				playerVars: {
					autoplay: 1,
					color: 'white',
					playsinline: 1,
					modestbranding: 1,
					controls: 1,
					mute: 1,
					loop: 1,
					autohide: 1
				},
				events: {
					'onReady': (event) => {
						event.target.mute();
						event.target.playVideo();
					},
					'onStateChange': (event) => {
						console.log(event);
					},
					'onError': (event) => {
						console.log(event);
					}
				}
			});
		}

		loadGoogleScript();

		return instance;
	};

	return instance;
}
