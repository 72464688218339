/**
 * Faq
 *
 * @selector [data-js="Faq"]
 * @enabled true
 */
import { base } from 'app/util/base';

export default function Faq() {
	// Private vars
	const instance = {};
	const showMoreClasses = ['Faq--showmore', 'Faq--showless'];
	let rows = [];
	let search;
	let timeoutId;

	const clickButtonHandler = element => {
		if (
			element.target.classList.contains('Faq--accordion') ||
			element.target.parentNode.classList.contains('Faq--accordion')
		) {
			element.currentTarget.classList.toggle('Faq--expanded');
			return;
		}

		if (
			showMoreClasses.some(
				cssClass =>
					element.target.classList.contains(cssClass) ||
					element.target.parentNode.classList.contains(cssClass)
			)
		) {
			element.currentTarget.querySelector('.Faq--extra-section').classList.toggle('Faq--extra-expanded');
			return;
		}
	};

	const searchHandler = event => {
		clearTimeout(timeoutId);

		const value = event.target.value.toLowerCase();
		const xpath = './/*/text()';

		timeoutId = setTimeout(() => {
			rows.forEach(row => {
				if (!value) {
					row.setAttribute('data-hidden', 'false');
					return;
				}

				const result = document.evaluate(xpath, row, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
				let element = result.iterateNext();
				let ifAny = false;

				while (element && !ifAny) {
					ifAny = element.textContent.toLowerCase().includes(value);
					element = result.iterateNext();
				}

				if (ifAny) {
					row.setAttribute('data-hidden', 'false');
				} else {
					row.setAttribute('data-hidden', 'true');
				}
			});
		}, 500);
	};

	const bindEvents = () => {
		rows.forEach(button => button.addEventListener('click', clickButtonHandler));

		search.addEventListener('input', searchHandler);
	};

	const unbindEvents = () => {
		rows.forEach(button => button.removeEventListener('click', clickButtonHandler));
		search.removeEventListener('input', searchHandler);
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));
		rows = document.querySelectorAll('.Faq--row');
		search = document.querySelector('#faq-search');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
