/**
 * ImagesAroundText
 *
 * @selector [data-js="ImagesAroundText"]
 * @enabled true
 */
import {base} from 'app/util/base';

export default function ImagesAroundText() {
	// Private vars
	const instance = {};

	const bindEvents = () => {
	};

	const unbindEvents = () => {
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
