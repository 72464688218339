/**
 * NewsSlider
 *
 * @selector [data-js="NewsSlider"]
 * @enabled true
 */
import {base} from 'app/util/base';

export default function NewsSlider() {
	// Private vars
	const instance = {};
	let newsletterButton;
	let closeButton;
	let popupElement;
	let body;

	const bindEvents = () => {
		if (newsletterButton) {
			newsletterButton.addEventListener('click', () => {
				popupElement.style.display = 'flex';
				setTimeout(function () {
					popupElement.classList.add('active');
					body.classList.add('newsletter-popup-open');
				}, 50);
			});
		}

		popupElement.addEventListener('click', (e) => {
			if (e.target.classList.contains('active')) {
				popupElement.classList.remove('active');
				body.classList.remove('newsletter-popup-open');
				setTimeout(function () {
					popupElement.style.display = 'none';
				}, 500);
			}
		});
		closeButton.addEventListener('click', () => {
			popupElement.classList.remove('active');
			body.classList.remove('newsletter-popup-open');
			setTimeout(function () {
				popupElement.style.display = 'none';
			}, 500);
		});
	};

	const unbindEvents = () => {
	};

	// Public vars

	// Public methods
	instance.init = element => {
		instance.el = element;
		Object.assign(instance, base(instance));

		newsletterButton = element.querySelector('button.newsletter');
		closeButton = element.querySelector('.NewsSlider--close');
		popupElement = element.querySelector('.NewsSlider--popup');
		body = document.querySelector('body');

		bindEvents();

		return instance;
	};

	instance.destroy = () => {
		unbindEvents();
	};

	return instance;
}
