// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
// Test via a getter in the options object to see if the passive property is accessed
window.HV = window.HV || {};
window.HV.supportsPassive = false;
try {
	let opts = Object.defineProperty({}, 'passive', {
		get: function() {
			window.HV.supportsPassive = true;
			return true;
		}
	});
	window.addEventListener('testPassive', null, opts);
	window.removeEventListener('testPassive', null, opts);
} catch (e) {
	// No support for passive listeners. sad
}

// Polyfill for closest (https://developer.mozilla.org/en-US/docs/Web/API/Element/closest)
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}
