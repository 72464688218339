import contextTrigger from 'lib/util/contextTrigger';
import ModuleManager from 'lib/util/ModuleManager';



import Button from '00-base/Button/Button';



import Slider from '01-widgets/Slider/Slider';



import Sliderv2 from '01-widgets/Sliderv2/Sliderv2';



import Sliderv3 from '01-widgets/Sliderv3/Sliderv3';



import Sliderv4 from '01-widgets/Sliderv4/Sliderv4';



import CardsRow from '02-content/CardsRow/CardsRow';



import CompleteCookieConsent from '02-content/CompleteCookieConsent/CompleteCookieConsent';



import ContactForm from '02-content/ContactForm/ContactForm';



import ContactFormUser from '02-content/ContactFormUser/ContactFormUser';



import CorporateSection02 from '02-content/CorporateSection02/CorporateSection02';



import CorporateSection05 from '02-content/CorporateSection05/CorporateSection05';



import EventsTestimonialGrid from '02-content/EventsTestimonialGrid/EventsTestimonialGrid';



import Faq from '02-content/Faq/Faq';



import HomeExperience from '02-content/HomeExperience/HomeExperience';



import ImageTextBlend from '02-content/ImageTextBlend/ImageTextBlend';



import ImagesAroundText from '02-content/ImagesAroundText/ImagesAroundText';



import IndividualSection02 from '02-content/IndividualSection02/IndividualSection02';



import InfoTeaserOffer from '02-content/InfoTeaserOffer/InfoTeaserOffer';



import Location from '02-content/Location/Location';



import NewsSlider from '02-content/NewsSlider/NewsSlider';



import NextArticleTeaser from '02-content/NextArticleTeaser/NextArticleTeaser';



import Panorama from '02-content/Panorama/Panorama';



import PictureContent04 from '02-content/PictureContent04/PictureContent04';



import SocialMediaFeed from '02-content/SocialMediaFeed1/SocialMediaFeed';



import TeaserCards from '02-content/TeaserCards/TeaserCards';



import VideoCta from '02-content/VideoCta/VideoCta';



import Header from '03-global/Header/Header';



import LangSelect from '03-global/LangSelect/LangSelect';



import Logo from '03-global/Logo/Logo';



export default (function() {
	let time = new Date();


	contextTrigger.add('[data-js="Button"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Button));
	});


	contextTrigger.add('[data-js="Slider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Slider));
	});


	contextTrigger.add('[data-js="Sliderv2"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Sliderv2));
	});


	contextTrigger.add('[data-js="Sliderv3"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Sliderv3));
	});


	contextTrigger.add('[data-js="Sliderv4"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Sliderv4));
	});


	contextTrigger.add('[data-js="CardsRow"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CardsRow));
	});


	contextTrigger.add('[data-js="CompleteCookieConsent"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CompleteCookieConsent));
	});


	contextTrigger.add('[data-js="ContactForm"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ContactForm));
	});


	contextTrigger.add('[data-js="ContactFormUser"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ContactFormUser));
	});


	contextTrigger.add('[data-js="CorporateSection02"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CorporateSection02));
	});


	contextTrigger.add('[data-js="CorporateSection05"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(CorporateSection05));
	});


	contextTrigger.add('[data-js="EventsTestimonialGrid"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(EventsTestimonialGrid));
	});


	contextTrigger.add('[data-js="Faq"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Faq));
	});


	contextTrigger.add('[data-js="HomeExperience"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(HomeExperience));
	});


	contextTrigger.add('[data-js="ImageTextBlend"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ImageTextBlend));
	});


	contextTrigger.add('[data-js="ImagesAroundText"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(ImagesAroundText));
	});


	contextTrigger.add('[data-js="IndividualSection02"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(IndividualSection02));
	});


	contextTrigger.add('[data-js="InfoTeaserOffer"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(InfoTeaserOffer));
	});


	contextTrigger.add('[data-js="Location"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Location));
	});


	contextTrigger.add('[data-js="NewsSlider"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(NewsSlider));
	});


	contextTrigger.add('[data-js="NextArticleTeaser"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(NextArticleTeaser));
	});


	contextTrigger.add('[data-js="Panorama"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Panorama));
	});


	contextTrigger.add('[data-js="PictureContent04"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(PictureContent04));
	});


	contextTrigger.add('[data-js="SocialMediaFeed"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(SocialMediaFeed));
	});


	contextTrigger.add('[data-js="TeaserCards"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(TeaserCards));
	});


	contextTrigger.add('[data-js="VideoCta"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(VideoCta));
	});


	contextTrigger.add('[data-js="Header"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Header));
	});


	contextTrigger.add('[data-js="LangSelect"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(LangSelect));
	});


	contextTrigger.add('[data-js="Logo"]', function() {
		let elem = this;

		(function(Module) {
			ModuleManager.connect( Module, elem );
		}(Logo));
	});



	contextTrigger.validate(document.body);

	console.log('Selecting components took: ', new Date() - time, 'ms');
}());
