import { translateValue } from './translate-value';

/**
 * Animate multiple props on an element
 * (Heavily inspired by GSAPs TweenLite.to method.)
 *
 * @param el
 * @param duration
 * @param props
 * @param easing
 * @param delay
 * @param settle
 * @param stagger
 * @param onAbort
 * @param onStart
 * @param onComplete
 * @param onUpdate
 */
export const animateProps = ({
	el,
	duration,
	props,
	easing,
	delay,
	settle,
	stagger,
	onAbort,
	onStart,
	onComplete,
	onUpdate
}) => {
	if (el === undefined || duration === undefined || props === undefined) {
		throw new Error('Required arguments (el, props, duration) not set.');
	}

	if (el instanceof window.HTMLElement || el instanceof window.SVGElement) {
		el = [el];
	}

	let startTime;
	let value;
	let renderId;
	let started = false;
	let posTotal;
	let pos;
	let currentTimeDiff;
	let paused = false;
	let pauseTime;

	onAbort = onAbort || function() {};
	onStart = onStart || function() {};
	onComplete = onComplete || function() {};
	onUpdate = onUpdate || function() {};
	easing =
		easing ||
		function(x) {
			return x;
		};
	delay = delay || 0;
	settle = settle || 0;
	stagger = stagger || 0;

	const additionalDuration = stagger * (el.length - 1);

	const set = ({ element, props, pos }) => {
		for (let i = 0; i < props.length; i++) {
			value =
				(props[i].prefix || '') +
				translateValue(easing(pos), 0, 1, props[i].start, props[i].end, 3) +
				(props[i].suffix || '');

			element.style[props[i].propName] = value;
		}
	};

	const setProps = ts => {
		currentTimeDiff = ts - startTime;
		posTotal = currentTimeDiff / (duration + additionalDuration);

		for (let i = 0; i < el.length; i++) {
			if (currentTimeDiff < i * stagger + duration && currentTimeDiff >= i * stagger) {
				pos = (currentTimeDiff - i * stagger) / duration;

				set({ element: el[i], props, pos });
			} else if (currentTimeDiff >= i * stagger + duration) {
				set({ element: el[i], props, pos: 1 });
			}
		}

		onUpdate({
			progress: posTotal,
			time: currentTimeDiff
		});
	};

	const anim = ts => {
		if (ts - startTime < 0) {
			// Don't animate when startTime is not reached (i.e. a delay has been set)
			renderId = window.requestAnimationFrame(anim);
			return;
		}

		// Animation is finished.
		// Set all props to their defined end values.
		if (ts - startTime >= duration + additionalDuration) {
			setProps(startTime + duration + additionalDuration);

			// Fire callback
			setTimeout(function() {
				onComplete();
			}, settle);

			return;
		}

		if (!started) {
			onStart();

			started = true;
		}

		// animation
		setProps(ts);
		renderId = window.requestAnimationFrame(anim);
	};

	startTime = window.performance.now() + delay;
	renderId = window.requestAnimationFrame(anim);

	return {
		pause: () => {
			window.cancelAnimationFrame(renderId);
			pauseTime = window.performance.now();
			paused = true;
		},
		resume: () => {
			if (paused) {
				// start anim again
				startTime += window.performance.now() - pauseTime;
				window.requestAnimationFrame(anim);
				paused = false;
			}
		},
		abort: () => {
			window.cancelAnimationFrame(renderId);

			// Fire callback
			onAbort();
		}
	};
};

export const animateValue = ({ duration, start, end, easing, delay, onAbort, onStart, onComplete, onUpdate }) => {
	onAbort = onAbort || function() {};
	onStart = onStart || function() {};
	onComplete = onComplete || function() {};
	onUpdate = onUpdate || function() {};

	let startTime;
	let pos;
	let value;
	let renderId;

	delay = delay || 0;

	easing =
		easing ||
		function(x) {
			return x;
		};

	const anim = ts => {
		if (ts - startTime >= duration) {
			onUpdate.call(null, end);
			onComplete();
			return;
		}

		// animation
		pos = (ts - startTime) / duration;

		value = translateValue(easing(pos), 0, 1, start, end, 3);
		onUpdate.call(null, value);

		renderId = requestAnimationFrame(anim);
	};

	startTime = window.performance.now() + delay;

	onStart();
	renderId = requestAnimationFrame(anim);

	return {
		abort: () => {
			window.cancelAnimationFrame(renderId);

			// Fire callback
			onAbort();
		}
	};
};

export const timer = (cb, delay) => {
	let instance = {};
	let timerId;
	let startTime;
	let remaining = delay;

	instance.resume = () => {
		startTime = window.performance.now();
		clearTimeout(timerId);
		timerId = setTimeout(cb, remaining);
	};

	instance.pause = () => {
		clearTimeout(timerId);
		remaining -= window.performance.now() - startTime;
	};

	instance.resume();

	return instance;
};
